@import "../../css/variable.scss";

.youIMyContentContainer {
  width: 98%;
  min-height: 100%;
  height: auto;
  position: relative;
  margin: 1rem 0;
}

.youICard {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: $border-radius;
}

.cardMinHeight {
  min-height: 100%;
}

.cardTitle {
  font-size: 28px;
}
