.configuration-container-display-title-label{
    margin-top: 15px !important;
}

.builder-configuration-form-card{
    margin: 5px 0px !important;
    .ant-card-head{
        font-size: 12px !important;
        min-height: 35px !important;
        padding: 0 20px !important;
        .ant-card-head-title{
            padding: 5px 0px !important;
        }
    }

}