@import "../../css/variable.scss";

.youIFullWidthBackground {
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 6px 6px 20px rgba(122, 122, 122, 0.212);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: -5;
}

.youIFullWidthContainer {
    position: relative;
    width: 100%;
    min-height: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}

.youIFullWidthContentContainer {
    width: 95%;
    height: 100%;
    position: relative;
}

.fullWidthBreadcrumbContainer {
    width: 100%;
    height: 2%;
    position: relative;
    margin-top: 1%;
    margin-bottom: 0;
    text-align: center;

    // white-space: nowrap;
}

.fullWidthPageTitle {
    margin-top: 1rem;

    h1 {
        font-weight: bold;
    }

    h2 {
        position: relative;
        // left: -10%;
    }
}

.innerContentContainer {
    position: relative;
    min-height: 82%;
    height: auto;
    width: 100%;
    margin-bottom: 2%;
}
