@import "../../css/variable.scss";

.offCanvasBodyContent {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100%;
  padding: 5% 0;
  font-size: $font-small-medium;
  color: black;

  &:hover {
    background-color: lightgrey;
    color: black;
    cursor: pointer;
  }
}

.offCanvasBodyTextContainer {
  width: 90%;
  height: 100%;
  padding-left: 5%;
  display: flex;
  flex-direction: row;
}

.offCanvasRightArrowContainer {
  width: 10%;
  height: 100%;
}

.offCanvasIcon {
  margin-right: 0.75rem;
}
