.store-detail-card {
  //margin: 5px 5px !important;
  cursor: pointer !important;
  border-radius: 15px !important;
}

.site-card-wrapper {
  .store-detail-card-wrapper {
    padding: 5px 10px !important;
    .ant-card-cover {
      height: 115px !important;
      img {
        height: inherit !important;
      }
    }
    .ant-card-body {
      border-top: 2px solid #f2f2f2;
    }
  }
}
