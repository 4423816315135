.ant-tabs-tab-btn{
    margin: 0px 20px !important;
    justify-self: center !important;
}

.ant-tabs-nav-wrap{
    background-color: white !important;
    border-radius: 10px !important;
}

.ant-tabs-nav{
    margin-bottom: 5px !important;
}