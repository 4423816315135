.imageManagerContentHeaderGroup {
  width: 100%;
  height: 5%;
  position: relative;
  display: flex;
}

.leftButtonContainer {
  width: 100%;
  // margin-left: auto;
}

.rightButtonContainer {
  // width: 100%;
  margin-left: auto;
}

.contentContainer {
  width: 100%;
  min-height: 85%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid black;
}

.imageComponentContainer {
  width: 90%;
  min-height: 100%;
  height: auto;
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  overflow: auto;
  margin-top: 3%;
}

.resourceItem {
  height: 100%;
}

.modalResourceItem {
  height: 100%;
}

.modalResourceItem.active {
  background-color: grey;
}

.paginationContainer {
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 2%;
}
