@import "../../css/variable.scss";

.page404Class {
    font-family: $font-family;
    position: absolute;
    top: -5%;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1500;
}

.contentContainer {
    width: 60%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imageContainer {
    width: 50%;
    height: 50%;
}

.imageClass {
    width: 100%;
    height: 100%;
}

.titleContainer {
    width: 100%;
    height: 10%;
    margin: 5%;
    text-align: center;
}

.messageContainer {
    p {
        font-size: 1.2rem;
    }
}

.buttonContainer {
    width: 100%;
    height: 20%;
    margin-top: 5%;
    text-align: center;
}
