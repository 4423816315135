.search-button {
  -webkit-tap-highlight-color: transparent !important;
}
.search-panel {
  color: white;
  height: 40px !important;
  background-color: white;
  .ant-input-group-addon {
    cursor: pointer !important;
  }
  .ant-input-wrapper > *{
    height: 40px !important;
  }
}
.ant-select-selection-selected-value {
  width: 100%;
}

.ant-input-group-addon {
  color: white;
  background-color: white;
  * {
    font-size: 15px;
    margin-bottom: 0;
  }
}

.search-panel-container {
  .ant-tag {
    .anticon {
      vertical-align: 0.1em !important;
    }
  }
}

.search-panel-filter-modal {
  .ant-picker-range {
    height: 40px !important;
  }
}

.filter-icon, .filter-tag-listing{
  padding: 0 0 !important;
}

@media screen and (max-width: 400px) {
  .filter-icon{
    padding: 10px 0 10px 2px !important;
  }
  .filter-tag-listing{
    padding: 5px 10px !important;
  }
}

