.custom-modal{
    .ant-modal-header{
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
    .header-button{
        color: #1890ff !important;
        cursor: pointer !important;
        font-size: 0.9rem !important;
    }
    .ant-modal-close-x{
        display: none !important;
    }
    .modal-close-button{
        font-size: 20px !important;
    }
}