@import "../../../../css/variable.scss";

.component {
  position: relative;
  width: 95%;
  min-height: 100%;
  height: auto;

  // border: 1px solid black;
}

.boxBorder {
  border: 1px solid blue;
}

.row {
  position: relative;
  width: 100%;
  display: flex;
}

.pagination {
  // width: 100%;
  // height: 5%;
  // display: flex;
  // position: relative;
  // margin-top: 2%;
  margin: 5px 10px;
}

.advBox {
  border: 1px solid black;
}

.link {
  margin-top: 15px;
}

.tagRight {
  display: flex;
  justify-content: space-between;
}

.subscriptionCard {
  border-radius: 30px;
  overflow: auto;
}
