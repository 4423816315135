@import "../../css/variable.scss";

.selectedImageOuterContainer {
  width: 100%;
  margin-top: 3rem;
  padding-left: 5%;

  span {
    font-weight: bold;
  }
}

.selectedImageInnerContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.imageContainer {
  border: 1px solid lightgrey;
  width: 200px;
  border-radius: $border-radius;
  position: relative;
  padding: 0.5rem 0.5rem;

  img {
    width: 100%;
    border-radius: $border-radius;
  }

  .closeButton {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5%;
    right: 2%;
    background-color: white;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
