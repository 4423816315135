@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

$font-family: "Poppins", sans-serif;
$global-background-image: url("https://wallpaperaccess.com/full/1567846.jpg");
$border-radius: 0.625rem;

/*color*/

/*font-size*/
$font-small: 0.75rem;
$font-small-medium: 1rem;
$font-medium: 1.5rem;
$font-medium-large: 2.25rem;
$font-large: 3rem;


$modalBorderRadius: 10px;