.builder-custom-editor-container{
    .active{
        border: red solid !important;
    }
    .view-controls{
        svg{
            vertical-align:  middle !important;
        }
    }
}
.sketch-picker{
    position: absolute !important;
}