$borderRadius: 10px;
.listing-layout{
    border-radius: $borderRadius !important;
    background-color: white !important;
    .ant-tabs-nav-wrap{
        border-radius: $borderRadius $borderRadius 0 0 !important;
    }
    table, .ant-table-container, .table-responsive{
        border-radius: 0 0 $borderRadius $borderRadius !important;
    }
}