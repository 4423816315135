.block-manager-modal{
    .ant-card-body{
        border: unset !important;
        cursor: pointer !important;
    }
    .block-category-image{
        max-height: 50px !important;
    }
    .block-image{
        max-height: 50px !important;
    }
}