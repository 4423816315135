@import "variable.scss";

/*login_register css*/
* {
  font-family: $font-family;
}

.suspenseSpinnerContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

nav {
  width: 100%;
  height: 7%;
}

svg {
  vertical-align: -5%;
}

svg.iconify {
  vertical-align: -10%;
}

.navbar {
  position: fixed;
  top: 0;
  transition: 1s;
  z-index: 1000;
}

.dismissNavbar {
  transition: 1s;
  top: -7%;
}

hr {
  margin-bottom: 2%;
}

.you-i-heading {
  margin-bottom: 2rem;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.auth-form {
  padding: 3rem;
}

.backdropFilter {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(10px);
}

.resource-item-context-menu {
  background-color: white;
  width: 10rem;
  height: 20rem;
}

.ant-modal,
.widerModal {
  position: relative;
  top: 3%;
}

.font-12 {
  font-size: 12px;
}

.font-18 {
  font-size: 18px;
}

.font-24 {
  font-size: 24px;
}

.forget-password-link {
  text-align: right;
}

.sign-up-link {
  text-align: center;
}

.ant-input,
.ant-input-password {
  font-size: 18px;
  border-radius: 5px;
}

.required {
  color: red;
  font-size: 0.6rem;
}

.ant-modal-border-radius {
  border-radius: $border-radius;
}

@media (min-aspect-ratio: 16/9) {
  .root {
    width: calc(1600vh / 9);
    height: 100vh;
  }
}

@media (max-aspect-ratio: 16/9) {
  .root {
    width: 100vw;
    height: calc(900vw / 16);
  }
}

@media screen and (max-width: 992px) {
  .you-i-half-width-container {
    zoom: 0.75;
  }

  .user-sidebar {
    display: none;
  }
}

//hide froala editor watermark
.fr-second-toolbar > #fr-logo {
  display: none !important;
}

.custom-span-button {
  cursor: pointer !important;
}

.ant-table{
  width: unset;
}

@media screen and (max-width: 400px) {
  .ant-table{
    width: fit-content !important;
  }
}

.ant-modal-footer{
 border-radius: $modalBorderRadius !important;
}

.ant-modal-content, .ant-modal-header{
  border-radius: $modalBorderRadius !important;
}

.rounded-card{
  border-radius: 15px !important;
}

.sketch-picker {
  z-index: 9999999 !important;
}