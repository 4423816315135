@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

#mobile-view {
  display: none;
}

#desktop-view {
  display: block;
}

.desktop-view-menu-icon {
  margin-right: 2%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-view-menu-icon:hover {
  color: #0b5ed7;
  cursor: pointer;
}

.desktop-view-menu-icon.active {
  color: #0b5ed7;
}

.navbar {
  padding: 0.5rem 2rem;
  background: #f0fcef;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  box-shadow: 0 5px rgba(122, 122, 122, 0.212);
}

.offcanvas-initial-button {
  margin-right: 2rem;
  color: black;
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
}

.auth-link {
  background-color: #55c2ce;
  border: solid 2px #55c2ce;
  border-radius: 5px;
  margin: 0 1rem;
}

.nav {
  padding: 0.3rem 1rem;
}

.language {
  background-color: transparent;
  color: black;
  border: none;
}

.language:hover {
  color: #0b5ed7;
  cursor: pointer;
}

.language.active {
  color: #0b5ed7;
}

.link {
  text-decoration: none;
  color: #55c2ce;
}

.logo {
  text-decoration: none;
  color: black;
}

.logo:hover {
  color: black;
}

.language-button {
  width: 60%;
  padding: 1rem;
  list-style: none;
}

.language-button:hover {
  cursor: pointer;
}

.language-button.active {
  padding: 1rem;
  list-style: none;
  border: solid 1px black;
}

.navText {
  margin-left: 10px;
}

.navDropdownList {
  display: flex;
  flex-direction: row;
}

.dropdownIcon {
  margin-right: 0.5rem;
}

@media screen and (max-width: 992px) {
  #mobile-view {
    display: block;
  }

  .desktop-view-menu-icon {
    margin-right: 2%;
    display: none;
  }

  .navbar {
    zoom: 0.75;
  }

  .navbar-toggler {
    display: none;
  }

  .nav-right {
    position: relative;
    right: 0;
  }

  .navbar-brand {
    zoom: 0.75;
  }
}
