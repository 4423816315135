@import "../../css/variable.scss";

.youIBackground {
    width: 100%;
    height: 100%;
    position: fixed;
    background-image: $global-background-image;
    filter: blur(10px);
    z-index: -10;
}

.youIBody {
    position: absolute;
    top: 7%;
    width: 100%;
    min-height: 93%;
    height: auto;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    transition: 1s;
}

.youIBody.navActive {
    transition: 1s;
    top: 0%;
    min-height: 100%;
}

.youIContent {
    position: relative;
    width: 100%;
    height: 100%;
}
