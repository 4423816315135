.youIBodyContainer {
    width: 100%;
    // height: 100%;
    min-height: 100%;
    height: auto;
    position: relative;
    // position: absolute;
    // border: 1px solid red;
    overflow-y: hidden;
}

.youICol {
    // position: absolute;
    min-height: 100%;
    // height: auto;
    // border: 1px solid red;
}

.youIHalfWidthContainer {
    margin: 2rem;
    padding: 3rem 0;
    background-color: white;
    box-shadow: 6px 6px 20px rgba(122, 122, 122, 0.212);
    // width: 100%;
    height: 100%;
    border-radius: 15px;
}

.halfWidthPageTitle {
    text-align: center;
    margin: 3rem 0rem;
}
