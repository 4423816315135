@import "../../css/variable.scss";

.sidebarOverlay {
    position: fixed;
    width: 100%;
    height: 93%;
    top: 7%;
    z-index: 1050;
    background: rgba(0, 0, 0, 0.3);
}

.userSidebar {
    position: fixed;
    top: 7%;
    left: -30%;
    background-color: white;
    width: 25%;
    height: 93%;
    z-index: 1200;
    display: flex;
    transition: 1s;
}

.userSidebar.active {
    left: 0%;
    transition: 1s;
}

.userSidebarContainer {
    width: 100%;
    height: auto;
    position: relative;
}

.userSidebarContent {
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: white;
    width: 100%;
    padding: 5% 0;
    font-size: $font-small-medium;

    &:hover {
        background-color: lightgrey;
        color: black;
        cursor: pointer;
    }
}

.sidebarTextContainer {
    width: 90%;
    height: 100%;
    padding: 0 10%;
}

.sidebarIconContainer {
    width: 10%;
    height: 100%;
    color: black;
}
