

.builder-view-control-group{
    border-radius: 5px;
    border:2px solid black;
    padding: 0px;
    .view-controls{
        width: 33.33%;
        padding-left: 10px !important;
        padding-right: 10px !important;
        text-align: center !important;
    }
    .view-controls:nth-child(2){
        border-left: 2px solid black;
        border-right: 2px solid black;
    }
}

#builder-site-full-path-container{
    .site-full-path-input{
        background-color:white !important;
    }
    input{
        color: black !important;
    }
}

.builder-header-left-nav{
    font-size: 15px;
    vertical-align: middle;
    text-align: center;
    line-height: 2;
    margin-right: 5px;

}