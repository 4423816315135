
    $borderRadius: 25px;
.store-card{
    margin: 5px 10px !important;
    cursor: pointer !important;
    border-radius: $borderRadius !important;
    .ant-card-cover{
        img{
            border-top-left-radius: $borderRadius !important;
            border-top-right-radius: $borderRadius !important;
        }
    }
}
.store-card-wrapper{
    padding: 5px 10px !important;
    .ant-card-body{
        border-top: 2px solid #f2f2f2;
    }
    .ant-card-actions{
        border-bottom-left-radius: $borderRadius !important;
        border-bottom-right-radius: $borderRadius !important;
    }
}