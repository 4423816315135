.page-grid-listing-wrapper {
  padding: 5px 10px !important;
  .ant-card-cover {
    height: 115px !important;
    img {
      height: inherit !important;
    }
  }
  .ant-card,
  .ant-card-actions {
    border-radius: 10px !important;
  }
}
